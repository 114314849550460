import { Visibility } from '@mui/icons-material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { Box, Checkbox, Chip, Stack, Typography } from '@mui/material';
import apis from 'api/api';

import { DynamicLoadingChecklist } from 'components/CustomMUI';
import { ActionsDropdown, DataTable, TableSkeleton } from 'components/Table';
import { formatDuration as dateFnsFormatDuration } from 'date-fns';
import { useApi } from 'hooks';
import { useFetcher } from 'hooks/useFetcher';
import { ModuleEditDialogOpenKey } from 'pages/Module/ModuleList';
import { useTableSearch } from 'pages/Settings/context';
import { memo, useEffect, useState } from 'react';
import { useDialogStore, useItemStore } from 'store';
import { handleExportPPTX } from 'utils';
import { getOriginalZonedDate } from 'utils/date';
import { EditDialog as ModuleEditDialog } from '../Module/common/EditDialog';
import { DurationProgressBar } from './common/DurationProgressBar';
import ModuleSearchBar from './common/ModuleSearchBar';
import { ModuleSelectionTableColumns } from './common/const';
import { getStyles } from './common/styles';
import { useJourney } from './common/useJourney';
import { useModuleSelection } from './common/useModuleSelection';

export const SelectionType = {
  AI: 'ai',
  ADD: 'add',
  EDIT: 'edit',
  FINAL: 'final',
};
export const ModuleSelection = ({
  loadingText = '',
  moduleLoading,
  journeyId,

  selectionType = SelectionType.ADD,
}) => {
  const [journeyInfo, setJourneyInfo] = useState(null);
  const [filterMode, setFilterMode] = useState('all');
  const itemStore = useItemStore((state) => state.items);
  const dialogState = useDialogStore((state) => state.editDialog);
  const setEditDialog = useDialogStore((state) => state.setEditDialog);
  const [exporting, setExporting] = useState({});
  const [checklistItems, setChecklistItems] = useState([]);
  const [loadingChecklistItems, setLoadingChecklistItems] = useState([]);
  const [exportModuleTitles, setExportModuleTitles] = useState('');
  const styles = getStyles();
  const { fetchModules, isFetchingModules } = useJourney();

  const {
    call: getJourneyDetails,
    loading: journeyIsLoading,
    data: journeyDet,
  } = useApi({
    fetcher: apis.getJourney,
    onSuccess: (data) => {
      setJourneyInfo(data);
    },
    //queryKey: ['getJourney', journeyId],
  });

  const {
    call: getTranslatedModules,
    isLoading: isFetchingTranslation,
    data: translatedModules,
  } = useFetcher({
    fetcher: apis.getTranslatedModules,
  });

  const {
    selectedModules,
    currentDuration,
    progressPercentage,
    handleModuleChange,
    handleSectionChange,
  } = useModuleSelection({
    journeyInfo,
    selectionType,
    setFilterMode,
    translatedModules: translatedModules?.map((item) => item?.translatedModule),
  });

  const { call: generatePPT } = useFetcher({
    fetcher: apis.generatePPT,
    successMessage: 'PPT generated successfully',
  });

  const {
    batchCall: fetchBatchModuleDetails,
    batchLoading: isBatchLoadingRunning,
    data: batchModuleDetails,
  } = useApi({
    fetcher: apis.getModuleById,
    queryKey: ['getModuleById'],
  });

  const getModuleList = () => {
    const allModules = itemStore?.modules;

    if (selectionType === SelectionType.AI) {
      const aiModuleIds = journeyInfo?.aiModuleId ?? [];
      const aiModules = itemStore?.modules?.filter((module) =>
        aiModuleIds.includes(module._id)
      );
      return aiModules;
    } else if (selectionType === SelectionType.ADD) {
      const journeyModules = journeyInfo?.modules;
      if (journeyModules?.length) {
        return allModules?.filter((module) =>
          journeyModules?.some((item) => item.moduleId === module._id)
        );
      }
      return allModules ?? [];
    } else if (selectionType === SelectionType.EDIT) {
      return journeyInfo?.aiModuleId?.length ? batchModuleDetails : allModules;
    } else if (selectionType === SelectionType.FINAL) {
      return translatedModules?.map((item) => item?.translatedModule);
    }
  };

  const searchFields = ['title', 'type'];
  const { setSearchTerm, filteredData, searchTerm } = useTableSearch({
    data: getModuleList(),
    searchFields,
    filter: (item) => {
      if (filterMode === 'selected') {
        return selectedModules?.some((module) => module.moduleId === item._id);
      }

      return item.status !== 'archive';
    },
    dependencies: [selectedModules, filterMode],
  });

  const getJourneyModules = () => {
    const uniqueModuleIds = new Set();
    const journeyModules = journeyInfo.modules;
    journeyModules.forEach((item) => {
      uniqueModuleIds.add(item.moduleId);
    });
    const uniqueModuleIdArray = Array.from(uniqueModuleIds);
    const requestPayloads = uniqueModuleIdArray.map((id) => [{ moduleId: id }]);
    fetchBatchModuleDetails(requestPayloads);
  };

  useEffect(() => {
    fetchModules();
  }, []);

  useEffect(() => {
    if (journeyInfo) {
      getJourneyModules();
      if (selectionType === SelectionType.FINAL && journeyId) {
        getTranslatedModules(journeyId);
      }
    }
  }, [journeyInfo, selectionType, journeyId]);

  useEffect(() => {
    if (journeyId /*selectionType === 'ai'*/) {
      getJourneyDetails({ journeyId });
    }
  }, [selectionType]);

  useEffect(() => {
    setChecklistItems([
      {
        id: 'exportModule',
        label: `Preparing ${exportModuleTitles} For Export`,
        isComplete: false,
        apiCall: generatePPT,
      },
    ]);
  }, [exportModuleTitles]);

  useEffect(() => {
    if (loadingText) {
      setLoadingChecklistItems([
        {
          id: 'loadingMessage',
          label: loadingText,
          isComplete: false,
        },
      ]);
    }
  }, [loadingText]);

  const handleExport = async (moduleId, moduleTitle) => {
    setExportModuleTitles(moduleTitle);
    try {
      setExporting((prev) => ({ ...prev, [moduleId]: true }));
      if (moduleId) {
        await handleExportPPTX({
          id: moduleId,
          title: moduleTitle,
          call: generatePPT,
        });
      }
    } catch (error) {
      console.error('Error generating PPT:', error);
    } finally {
      setExporting((prev) => ({ ...prev, [moduleId]: false }));
    }
  };

  const aiModulesLoading = moduleLoading;
  const modulesLoading =
    isFetchingModules || isBatchLoadingRunning || isFetchingTranslation;
  const disableCheckbox = !!journeyInfo?.aiModuleId?.length;

  return (
    <Stack direction="column" spacing={5}>
      <DurationProgressBar
        currentDuration={currentDuration}
        journeyInfo={journeyInfo}
        progressPercentage={progressPercentage}
      />

      <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
        <Typography variant="h6"></Typography>
        <ModuleSearchBar
          filterMode={filterMode}
          setFilterMode={setFilterMode}
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
        />
      </Stack>
      {aiModulesLoading && !dialogState.isOpen && (
        <DynamicLoadingChecklist
          isLoading={aiModulesLoading}
          checklistItems={[
            {
              id: 'fetchingAIRecommendation',
              label: 'Fetching Updated AI Recommendation. Please Wait...',
              isComplete: false,
            },
          ]}
          title="Loading AI Recommendations"
        />
      )}
      {modulesLoading ? (
        <TableSkeleton column={ModuleSelectionTableColumns} />
      ) : loadingText ? (
        <DynamicLoadingChecklist
          isLoading={true}
          checklistItems={loadingChecklistItems}
          title={'Loading AI Recommendations'}
        />
      ) : (
        <>
          <DataTable
            columns={ModuleSelectionTableColumns}
            sort={true}
            collapseContent={(row) => (
              <div>
                <strong>Details:</strong> {row.title}
              </div>
            )}
            data={filteredData?.map((item) => {
              const isModuleSelected = disableCheckbox
                ? true
                : selectedModules?.some(
                    (module) => module.moduleId === item._id
                  );

              return {
                ...item,
                title: (
                  <>
                    <DynamicLoadingChecklist
                      isLoading={exporting[item._id]}
                      checklistItems={checklistItems}
                      title={`Exporting Module Data To PPTX. Please Wait ..`}
                    />
                    <Checkbox
                      disabled={disableCheckbox}
                      size="xs"
                      color="primary"
                      checked={isModuleSelected}
                      onClick={(e) =>
                        handleModuleChange(item, e.target.checked)
                      }
                    />
                    {item.title}
                  </>
                ),
                actions: (
                  <ActionsDropdown
                    actions={[
                      {
                        label: 'View',
                        icon: <Visibility color="success" />,
                        onClick: () => {
                          setEditDialog({
                            isOpen: true,
                            data: item,
                            forItem: ModuleEditDialogOpenKey,
                          });
                        },
                      },
                      {
                        label: 'Export',
                        icon: <GetAppIcon color="success" />,
                        onClick: () => handleExport(item._id, item.title),
                        disabled: exporting[item._id],
                      },
                    ]}
                  />
                ),
                createdAt: item.createdAt
                  ? getOriginalZonedDate({
                      date: item.createdAt,
                    })
                  : '-',
                status: (
                  <Chip
                    label={item.status}
                    variant="outlined"
                    size="small"
                    sx={{ textTransform: 'capitalize' }}
                    color={item.status === 'ACCEPTED' ? 'success' : 'info'}
                  />
                ),
                type: (
                  <Chip
                    label={item.type ?? 'draft'}
                    variant="outlined"
                    size="small"
                    sx={{ textTransform: 'capitalize' }}
                    color={item.type === 'ACCEPTED' ? 'success' : 'info'}
                  />
                ),
                subRows: [
                  ...(item?.sections?.map((section, index) => {
                    const isSectionSelected = disableCheckbox
                      ? true
                      : selectedModules?.some(
                          (module) =>
                            module.moduleId === item._id &&
                            module?.sections?.some(
                              (sectionId) => sectionId === section._id
                            )
                        );

                    return {
                      title: (
                        <Box>
                          <Stack
                            direction="row"
                            spacing={1}
                            sx={styles.sectionContainer}
                          >
                            <Checkbox
                              key={section._id}
                              size="xs"
                              color="primary"
                              checked={isSectionSelected}
                              disabled={disableCheckbox}
                              onClick={(e) =>
                                handleSectionChange(
                                  item._id,
                                  section._id,
                                  e.target.checked
                                )
                              }
                            />
                            <Box
                              sx={{
                                maxWidth: '800px',
                                overflow: 'hidden',
                              }}
                            >
                              <Typography
                                variant="body2"
                                noWrap
                                sx={styles.sectionTitle}
                                title={section.title}
                              >
                                {`${section.title}`}
                              </Typography>
                            </Box>
                          </Stack>
                          <Box ml={3} width={'200%'}>
                            {section.subSections.map((subSection) => {
                              return (
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  sx={styles.sectionContainer}
                                >
                                  <Typography
                                    variant="body2"
                                    noWrap
                                    sx={styles.sectionTitle}
                                    title={subSection.title}
                                  >
                                    • {`${subSection.title}`}
                                  </Typography>
                                </Stack>
                              );
                            })}
                          </Box>
                        </Box>
                      ),
                      duration: dateFnsFormatDuration({
                        minutes: section.minutes,
                      }),
                    };
                  }) || []),
                ],
              };
            })}
          />
          <ModuleEditDialog forItem={ModuleEditDialogOpenKey} />
        </>
      )}
    </Stack>
  );
};
export default memo(ModuleSelection);
